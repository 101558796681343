import * as Yup from "yup";

export const validationSchema = Yup.object()
  .shape({
    field1: Yup.string(),
    field2: Yup.string(),
  })
  .test("fields", "Either field1 or field2 must be filled", function (values) {
    const { field1, field2 } = values;
    if (!field1 && !field2) {
      return this.createError({
        path: "field1",
        message: "Enter Order OR Voucher Number",
      });
    }
    return true;
  });

export const validationSchema2 = Yup.object().shape({
  fname: Yup.string().required("Please enter the First Name"),
  lname: Yup.string().required("Please enter the Last Name"),
  email: Yup.string()
    .email("Invalid Email Address")
    .required("Please enter the Email"),
});

export const loginAdmin = Yup.object().shape({
  username: Yup.string().required("Please enter the User Name"),
  password: Yup.string().required("Please enter the Password"),
});

export const forgotPass = Yup.object().shape({
  username: Yup.string()
    .email("Invalid Email Address")
    .required("Please enter the Email Address"),
});

export const setPassword = Yup.object({
  password: Yup.string()
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/\d/, "Password must contain at least one digit")
    .matches(
      /[!@#$%^&*(),.?":{}|<>]/,
      "Password must contain at least one special character"
    )
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Please confirm the password"),
});

export const quizSearch = Yup.object({
  fieldtype: Yup.string().required("Please Select a Column"),
  searchval: Yup.string().required("Please fill the search field"),
});

export const addProductSchema = Yup.object({
  name: Yup.string().required("Please enter the Product Name"),
  image: Yup.string().required("Please select the Product Image"),
});

export const orderFilter = Yup.object().shape({
  fname: Yup.string().required("Please enter the first name"),
  lname: Yup.string().required("Please enter the last name"),
  certificate: Yup.string().required("Please enter the certificate"),
  city: Yup.string().required("Please enter the city"),
  state: Yup.string().required("Please enter the state"),
  zip: Yup.string().required("Please enter the zip"),
  job: Yup.string().required("Please enter the job"),
  product: Yup.string().required("Please select the product"),
  email: Yup.string().email("Invalid Email Address"),
});
export const orderFilterBestBuy = Yup.object().shape({
  order_no: Yup.string().required("Please enter the order no"),
  fname: Yup.string().required("Please enter the first name"),
  lname: Yup.string().required("Please enter the last name"),
  city: Yup.string().required("Please enter the city"),
  state: Yup.string().required("Please enter the state"),
  zip: Yup.string().required("Please enter the zip"),
  product: Yup.string().required("Please select the product"),
  email: Yup.string().email("Invalid Email Address"),
});
